import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";

import Text from "@smartinspection/ameta-frontend-components/display/Text";

const StatusContent = ({
  title,
  imageUrl,
}: {
  title: string;
  imageUrl: string;
}) => {
  const [currentImgUrl, setCurrentImgUrl] = useState(imageUrl);

  const [, setTimeInterval] = useState<NodeJS.Timer>();

  useEffect(() => {
    setTimeInterval((prevInterval) => {
      prevInterval && clearInterval(prevInterval);
      return setInterval(() => {
        // Adds a random meaningless query parameter that
        // resets img src cache and reloads image
        setCurrentImgUrl(imageUrl + `?${Math.random()}`);
      }, 10000);
    });
  }, [imageUrl]);

  return (
    <Box
      sx={{
        p: 2,
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
    >
      <Text variant="h4">{title}</Text>
      <img alt={title} style={{ width: "90%" }} src={currentImgUrl} />
    </Box>
  );
};

export default StatusContent;
