import React from "react";
import { useSelector } from "react-redux";
import { TStore } from "redux/store";

import Card from "@smartinspection/ameta-frontend-components/display/Card";

import GridLayout from "@smartinspection/ameta-frontend-components/layout/GridLayout";
import VerticalSplit from "@smartinspection/ameta-frontend-components/layout/VerticalSplit";

import StatusContent from "components/StatusContent";

export default function Dashboard() {
  const globalState = useSelector((state: TStore) => state.global);

  return (
    <GridLayout
      spacing={64}
      editable={globalState.layoutEditable}
      layout={[
        {
          i: "ameta-backend",
          h: 15,
          w: 4,
          x: 4,
          y: 0,
        },
        {
          i: "ameta-road-owner",
          h: 15,
          w: 4,
          x: 0,
          y: 15,
        },
        {
          i: "ameta-traffic-counter",
          h: 15,
          w: 4,
          x: 4,
          y: 15,
        },
        {
          i: "ameta-delivery",
          h: 15,
          w: 4,
          x: 8,
          y: 15,
        },
      ]}
    >
      <Card
        key="ameta-backend"
        type="drawer"
        title="Ameta Backend"
        faIcon="server"
        bodySx={{ pt: 2 }}
      >
        <VerticalSplit
          divider
          left={
            <StatusContent
              title="Development"
              imageUrl="https://gitlab.com/smartinspection/ameta/ameta-backend/badges/dev/pipeline.svg"
            />
          }
          right={
            <StatusContent
              title="Production"
              imageUrl="https://gitlab.com/smartinspection/ameta/ameta-backend/badges/master/pipeline.svg"
            />
          }
        />
      </Card>
      <Card
        key="ameta-road-owner"
        type="drawer"
        title="Ameta Road Owner"
        faIcon="solar-panel"
        bodySx={{ pt: 2 }}
      >
        <VerticalSplit
          divider
          left={
            <StatusContent
              title="Development"
              imageUrl="https://gitlab.com/smartinspection/ameta/ameta-road-owner/badges/dev/pipeline.svg"
            />
          }
          right={
            <StatusContent
              title="Production"
              imageUrl="https://gitlab.com/smartinspection/ameta/ameta-road-owner/badges/master/pipeline.svg"
            />
          }
        />
      </Card>
      <Card
        key="ameta-traffic-counter"
        type="drawer"
        title="Ameta Traffic Counter"
        faIcon="solar-panel"
        bodySx={{ pt: 2 }}
      >
        <VerticalSplit
          divider
          left={
            <StatusContent
              title="Development"
              imageUrl="https://gitlab.com/smartinspection/ameta/ameta-traffic-counter/badges/dev/pipeline.svg"
            />
          }
          right={
            <StatusContent
              title="Production"
              imageUrl="https://gitlab.com/smartinspection/ameta/ameta-traffic-counter/badges/master/pipeline.svg"
            />
          }
        />
      </Card>
      <Card
        key="ameta-delivery"
        type="drawer"
        title="Ameta Delivery"
        faIcon="solar-panel"
        bodySx={{ pt: 2 }}
      >
        <VerticalSplit
          divider
          left={
            <StatusContent
              title="Development"
              imageUrl="https://gitlab.com/smartinspection/ameta/ameta-delivery/badges/dev/pipeline.svg"
            />
          }
          right={
            <StatusContent
              title="Production"
              imageUrl="https://gitlab.com/smartinspection/ameta/ameta-delivery/badges/master/pipeline.svg"
            />
          }
        />
      </Card>
    </GridLayout>
  );
}
