import React from "react";
import { useSelector } from "react-redux";
import { TStore } from "redux/store";

import ThemeProvider from "@smartinspection/ameta-frontend-components/style/ThemeProvider";
import { NotificationContainer } from "@smartinspection/ameta-frontend-components/utils/notification";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Provider } from "react-redux";
import store from "redux/store";

import Main from "Main";

import "faIcons";

const AppContent = () => {
  const globalState = useSelector((state: TStore) => state.global);
  return (
    <ThemeProvider colorMode={globalState.colorMode}>
      <Routes>
        <Route path="/" element={<Main />} />
      </Routes>
    </ThemeProvider>
  );
};

function App() {
  return (
    <div style={{ height: "100vh" }}>
      <BrowserRouter>
        <Provider store={store}>
          <AppContent />
          <NotificationContainer />
        </Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
