import React from "react";
import { Route, Routes } from "react-router-dom";

import Box from "@mui/material/Box";

import Dashboard from "pages/Dashboard";

export default function Main() {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", height: "100%" }}>
      <Routes>
        <Route path="/" element={<Dashboard />} />
      </Routes>
    </Box>
  );
}
